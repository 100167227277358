import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import { CreatePostPayload, Post, Skill, WorkExperienceLevel } from "../../../api/posts/types";
import { User } from "../../../api/users/types";
import { Category, EmploymentStatusType, Region } from "../../../api/utilities/types";
import Button from "../../../components/Button";
import VisibilityCheckbox from "../../../components/VisibilityCheckbox";
import PageContainer from "../../../components/common/PageContainer";
import { genderLabelMap } from "../../../utils/valueLabelMaps";
import { AvatarImage, AvatarPreview } from "../../Profile/components/AvatarUpload";
import PortfolioForm from "./components/PortfolioForm";
import WorkExperienceForm from "./components/WorkExperienceForm";

import background from "../../../components/common/assets/background.png";
import ServicesForm from "./components/ServicesForm";
import { ChangeEvent, useEffect, useState } from "react";
import useCreatePost from "../../../hooks/posts/useCreatePost";
import useUpdatePost from "../../../hooks/posts/useUpdatePost";
import { Facebook, GitHub, LinkedIn, Twitter } from "@mui/icons-material";
import CategoriesAutocomplete from "../components/CategoriesAutocomplete";
import { DatePicker } from "@mui/x-date-pickers";
import PricingCompareTable from "../../../components/PricingCompareTable";
import PricingMain from "../../../components/PricingMain";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const PortfoliosBackground = styled.div`
  background: url(${background});
  background-size: cover;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: calc(-50vw + 8px);
  margin-right: calc(-50vw + 8px);
  @media (min-width: 768px) and (max-width: 1080px) {
    margin-left: calc(-37.5vw);
    margin-right: calc(-37.5vw);
    max-width: 75vw;
  }
  @media (max-width: 767px) {
    margin-left: calc(-50vw + 3px);
    margin-right: calc(-50vw + 3px);
  }
`;

const FormHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 50px 0px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 5px 0px;
  }

  @media (min-width: 768px) and (max-width: 1080px) {
    padding: 10px 0px;
  }
`;

const ReadOnlyTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #dfdfdf;
  }
  .MuiFormLabel-root {
    color: #000;
  }
`;

const FieldAdornment = styled(InputAdornment)`
  padding-right: 25px;
`;

const HideCheckbox = styled.div`
  position: absolute;
  bottom: 0;
  right: -10px;
`;

const InfoGrid = styled(Grid)({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gridTemplateRows: "auto auto",
  alignItems: "center",
  rowGap: "0px",
  columnGap: "8px",
});

type CreatePostFormValues = Omit<Partial<CreatePostPayload>, "region_id" | "category_id"> & {
  title: string;
  phone: string;
  birthday?: string;
  gender?: string;
  region_id: number | string;
  category_id: number | string;
  subcategory_id?: number | string;
};

type CreatePostFormProps = {
  user: User;
  employmentStatuses: EmploymentStatusType[];
  regions: Region[];
  categories: Category[];
  editModeValues?: Post;
};

const CreatePostForm = ({
  user,
  employmentStatuses,
  regions,
  categories,
  editModeValues,
}: CreatePostFormProps) => {
  const createPost = useCreatePost();
  const updatePost = useUpdatePost();
  const navigate = useNavigate();

  const [currentSkillsInputValue, setCurrentSkillsInputValue] = useState("");

  const [showCustomCategoryField, setShowCustomCategoryField] = useState(false);

  const isEditing = !!editModeValues;

  const onSubmit = async (data: CreatePostFormValues) =>
    isEditing
      ? updatePost.mutate(
          {
            ...data,
            region_id: +data.region_id,
            category_id: +(data.subcategory_id || data.category_id),
            user: null,
          } as CreatePostPayload,
          {
            onSuccess: (resp) => {
              toast.success("Успешно ажуриран оглас.", { position: "bottom-center" });
              navigate(`/posts/${(resp as unknown as Post).id}`); // BE response doesn't match SuccessResponse type
            },
            onError: (error) => {
              console.error(error);
              toast.error("Настана грешка при ажурирање на огласот.", {
                position: "bottom-center",
              });
            },
          }
      )
      : "";
      // : createPost.mutate(
      //     {
      //       ...data,
      //       region_id: +data.region_id,
      //       category_id: +(data.subcategory_id || data.category_id),
      //     } as CreatePostPayload,
      //     {
      //       onSuccess: (resp) => {
      //         toast.success(
      //           "Успешно креиран оглас. Вашиот оглас е испратен на одобрување од администратор, по што ќе добиете порака.",
      //           { position: "bottom-center" },
      //         );
      //         navigate(`/posts/${(resp as unknown as Post).id}`); // BE response doesn't match SuccessResponse type
      //       },
      //       onError: (error) => {
      //         console.error(error);
      //         toast.error("Настана грешка при креирање на огласот.", { position: "bottom-center" });
      //       },
      //     }
      // );

  const initialValues: CreatePostFormValues = {
    title: "",
    description: "",
    phone: user?.phone || "",
    birthday: user?.profile?.birthday || "",
    gender: user?.profile?.gender || "",
    experience_years: undefined,
    work_experience_level: WorkExperienceLevel.Junior,
    salary: undefined,
    salary_currency: "MKD",
    current_company: "",
    region_id: regions.find((r) => r.name === user?.profile?.city)?.id || "",
    category_id: 0,
    hide_email: false,
    hide_first_name: false,
    hide_last_name: false,
    hide_avatar: false,
    hide_birthday: false,
    // hide_gender: false,
    hide_phone: false,
    hide_employment_status: false,
    hide_current_company: false,
    skills: [],
    services: [],
    work_experiences: [],
    portfolios: [],
    ...(editModeValues ? (editModeValues as unknown as CreatePostFormValues) : {}),
    ...(editModeValues &&
      editModeValues?.work_experience_level && {
      work_experience_level: (Object.entries(WorkExperienceLevel).find(
        ([, v]) => `${v}` === `${editModeValues?.work_experience_level}`,
      )?.[1] ?? 0) as (typeof WorkExperienceLevel)[keyof typeof WorkExperienceLevel],
    }),
    ...(editModeValues && {
      work_experiences: editModeValues.workExperiences?.map((we) => ({
        ...we,
        start_date: dayjs(we.start_date),
        end_date: we.end_date ? dayjs(we.end_date) : undefined,
      })),
    }),
  };

  const methods = useForm<CreatePostFormValues>({
    defaultValues: initialValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = methods;

  const checkAllHideFields = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setValue("hide_first_name", checked, { shouldDirty: true });
    setValue("hide_last_name", checked, { shouldDirty: true });
    setValue("hide_avatar", checked, { shouldDirty: true });
    setValue("hide_birthday", checked, { shouldDirty: true });
    setValue("hide_email", checked, { shouldDirty: true });
    // setValue("hide_gender", checked, { shouldDirty: true });
    setValue("hide_phone", checked, { shouldDirty: true });
    setValue("hide_employment_status", checked, { shouldDirty: true });
    setValue("hide_current_company", checked, { shouldDirty: true });
  };

  const categoryId = watch("category_id");

  useEffect(() => {
    if (categoryId) {
      setValue("subcategory_id", undefined);
    }
  }, [categoryId, setValue]);

  const showSubcategoryField =
    !!categories.filter((c) => c.parent_id === categoryId).length && !showCustomCategoryField;

  const currentCompanyDate = initialValues.current_company_start_date
    ? dayjs(initialValues.current_company_start_date)
    : null;

  return (
    <PageContainer>
      <FormProvider {...methods}>
        <FormHeaderContainer>
          <Typography variant="h3" color="secondary" sx={{ marginRight: "auto" }}>
            {isEditing ? "Измени" : "Креирај"} оглас
          </Typography>
          <AvatarPreview width="200px" height="200px">
            <AvatarImage isLoading={false} src={user?.profile?.avatar || ""} alt="Avatar preview" />
            <HideCheckbox>
              <VisibilityCheckbox {...register("hide_avatar")} checked={watch("hide_avatar")} />
            </HideCheckbox>
          </AvatarPreview>
        </FormHeaderContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5" color="secondary">
            Лични податоци
          </Typography>
          <InfoGrid>
            <Icon>info</Icon>
            <Typography variant="subtitle1">
              Можете да ги анонимизирате вашите лични податоци со што ќе бидат скриени од огласот.
            </Typography>
            <Grid item xs={12} />
            <Typography variant="subtitle2">
              Анонимните податоци ќе бидат прикажани само на вашите конекции.
            </Typography>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onChange={checkAllHideFields} />}
                  label="Анонимизирај ги сите податоци"
                />
              </FormGroup>
            </Grid>
          </InfoGrid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={user.user_type === "user" ? 6 : 12}>
              <Autocomplete
                fullWidth
                options={[user?.profile?.first_name || ""]}
                defaultValue={user?.profile?.first_name || ""}
                disabled
                renderInput={(params) => (
                  <ReadOnlyTextField
                    {...params}
                    label={`Име${user.user_type === "user" ? "" : " на компанија"}`}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityCheckbox
                            {...register("hide_first_name")}
                            checked={watch("hide_first_name")}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {user.user_type === "user" && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  options={[user?.profile?.last_name || ""]}
                  defaultValue={user?.profile?.last_name || ""}
                  disabled
                  renderInput={(params) => (
                    <ReadOnlyTextField
                      {...params}
                      label="Презиме"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <VisibilityCheckbox
                              {...register("hide_last_name")}
                              checked={watch("hide_last_name")}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {user.user_type === "user" && (
              <>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    options={[
                      dayjs(user?.profile?.birthday || "")
                        .format("DD/MM/YYYY")
                        .toString(),
                    ]}
                    defaultValue={dayjs(user?.profile?.birthday || "")
                      .format("DD/MM/YYYY")
                      .toString()}
                    disabled
                    renderInput={(params) => (
                      <ReadOnlyTextField
                        {...params}
                        label="Датум на раѓање"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <VisibilityCheckbox
                                {...register("hide_birthday")}
                                checked={watch("hide_birthday")}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    fullWidth
                    options={[
                      (user?.profile?.gender && genderLabelMap[user?.profile?.gender]) || "",
                    ]}
                    defaultValue={
                      (user?.profile?.gender && genderLabelMap[user?.profile?.gender]) || ""
                    }
                    disabled
                    renderInput={(params) => (
                      <ReadOnlyTextField
                        {...params}
                        label="Пол"
                        InputProps={{
                          ...params.InputProps,
                          // endAdornment: (
                          //   <InputAdornment position="end">
                          //     <VisibilityCheckbox
                          //       {...register("hide_gender")}
                          //       checked={watch("hide_gender")}
                          //     />
                          //   </InputAdornment>
                          // ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                options={[user?.email || ""]}
                defaultValue={user?.email || ""}
                disabled
                renderInput={(params) => (
                  <ReadOnlyTextField
                    {...params}
                    label="Email адреса"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityCheckbox
                            {...register("hide_email")}
                            checked={watch("hide_email")}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={showSubcategoryField ? 6 : 12}>
              <FormControl fullWidth error={!!errors.category_id}>
                <CategoriesAutocomplete
                  includeAll={false}
                  categoryId={0}
                  defaultValue={{
                    id: Number(initialValues.category_id),
                    name: categories.find((c) => c.id === initialValues.category_id)?.name || "",
                  }}
                  onChoose={(categoryId) => {
                    setValue("category_id", categoryId, { shouldValidate: true });
                  }}
                  inputProps={{
                    disabled: showCustomCategoryField,
                    variant: showCustomCategoryField ? "filled" : "outlined",
                  }}
                />
                <FormHelperText>{errors.category_id && "Полето е задолжително"}</FormHelperText>
              </FormControl>
            </Grid>
            {showSubcategoryField && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Подкатегорија</InputLabel>
                  <Select
                    label="Подкатегорија"
                    {...register("subcategory_id")}
                    defaultValue={initialValues.subcategory_id || ""}
                    disabled={
                      !watch("category_id") ||
                      !categories.filter((c) => c.parent_id === watch("category_id")).length ||
                      showCustomCategoryField
                    }
                  >
                    {categories
                      .filter((c) => c.parent_id === watch("category_id"))
                      .map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors.category_id && "Полето е задолжително"}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showCustomCategoryField}
                    onChange={(e) => setShowCustomCategoryField(e.target.checked)}
                  />
                }
                label="Не можеш да ја најдеш твојата категорија?"
              />
              {showCustomCategoryField && (
                <TextField
                  fullWidth
                  label="Нова категорија"
                  {...register("custom_category", { required: true })}
                  error={!!errors.custom_category}
                  helperText={errors.custom_category && "Полето е задолжително"}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Опис на оглас *"
                {...register("description", { required: true })}
                multiline
                rows={4}
                fullWidth
                error={!!errors.description}
                helperText={errors.description && "Полето е задолжително"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Телефонски број *"
                {...register("phone", { required: true })}
                fullWidth
                defaultValue={initialValues.phone || "0000"}
                InputProps={{
                  endAdornment: (
                    <FieldAdornment position="end">
                      <VisibilityCheckbox
                        {...register("hide_phone")}
                        checked={watch("hide_phone")}
                      />
                    </FieldAdornment>
                  ),
                }}
                error={!!errors.phone}
                helperText={errors.phone && "Полето е задолжително"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Вкупно работно искуство (во години) *"
                {...register("experience_years", { required: true })}
                type="number"
                InputProps={{inputProps: { min: 0 }}}
                fullWidth
                error={!!errors.experience_years}
                helperText={errors.experience_years && "Полето е задолжително"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.work_experience_level}>
                <InputLabel id="work-experience-level-label">Ниво на позиција *</InputLabel>
                <Select
                  label="Ниво на позиција *"
                  {...register("work_experience_level", { required: true })}
                  defaultValue={initialValues.work_experience_level || ""}
                >
                  {Object.entries(WorkExperienceLevel).map(([key, value]) => (
                    <MenuItem key={value} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.work_experience_level && "Полето е задолжително"}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.region_id}>
                <InputLabel variant="outlined" id="region-label">
                  Град *
                </InputLabel>
                <Select
                  label="Град *"
                  {...register("region_id", { required: true })}
                  defaultValue={initialValues.region_id || 0}
                >
                  {regions.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.region_id && "Полето е задолжително"}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Typography variant="h5" color="secondary">
            Плата и дополнителни услуги
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.employment_status}>
                <InputLabel id="employment-status-label">Тип на работа *</InputLabel>
                <Select
                  label="Тип на работа"
                  {...register("employment_status", { required: true })}
                  // defaultValue={initialValues.employment_status}
                >
                  {employmentStatuses.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {errors.employment_status && "Полето е задолжително"}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Очекувана плата *"
                {...register("salary", { required: true })}
                type="number"
                fullWidth
                error={!!errors.salary}
                helperText={errors.salary && "Полето е задолжително"}
                defaultValue={22567}
                
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <Icon>money</Icon> */}
                      MKD
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Autocomplete
                hidden={true}
                id="currency"
                options={[
                  { value: "MKD", label: "MKD" },
                  // { value: "EUR", label: "EUR" },
                  // { value: "USD", label: "USD" },
                ]}
                defaultValue={
                  initialValues.salary_currency
                    ? {
                      value: initialValues.salary_currency,
                      label: initialValues.salary_currency,
                    }
                    : { value: "MKD", label: "MKD" }
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField
                    hidden={true}
                    {...params}
                    label="Валута"
                    {...register("salary_currency", { required: true })}
                    error={!!errors.salary_currency}
                    helperText={errors.salary_currency && "Полето е задолжително"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography color="secondary">
            Доколку нудите дополнителни услуги или работите исклучиво преку договор на дело, ве
            молиме внесете ги услугите кои ги нудите.
          </Typography>
          <ServicesForm />

          <Typography variant="h5" color="secondary">
            Работно искуство
          </Typography>
          <Grid item xs={12}>
            <Controller
              name="skills"
              control={control}
              defaultValue={[]}
              render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
                <Autocomplete
                  {...field}
                  freeSolo
                  multiple
                  handleHomeEndKeys
                  options={[]}
                  value={Array.isArray(field.value) ? field.value : []}
                  getOptionLabel={(o) => (typeof o === "string" ? o : o.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Вештини (пр. Word, Excel, Тимски играч) - разделени со запирка "
                      inputRef={ref}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                  onChange={(_, data) => {
                    if (data) field.onChange(data);
                  }}
                  inputValue={currentSkillsInputValue}
                  onInputChange={(_, newInputValue) => {
                    setCurrentSkillsInputValue(newInputValue);
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      setValue(
                        "skills",
                        (field.value || []).concat(
                          options.map((e) => ({ name: e.trim() })).filter((x) => !!x.name),
                        ),
                      );
                      setCurrentSkillsInputValue("");
                    }
                  }}
                  onBlur={() => {
                    if (currentSkillsInputValue) {
                      const currentSkills = field.value || [];
                      const newSkills: Skill[] = currentSkillsInputValue
                        .split(",")
                        .map((s) => ({ name: s.trim() }));
                      setValue("skills", [...currentSkills, ...newSkills], { shouldDirty: true });
                      setCurrentSkillsInputValue("");
                    }
                  }}
                />
              )}
            />
          </Grid>
          {user.user_type === "user" && (
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Име на моментална компанија"
                  {...register("current_company")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VisibilityCheckbox
                          {...register("hide_current_company")}
                          checked={watch("hide_current_company")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Работна позиција"
                  {...register("current_company_position")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Опис на работно место"
                  {...register("current_company_description")}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  views={["year", "month"]}
                  sx={{
                    width: "100%",
                  }}
                  format="MM-YYYY"
                  label="Датум на започнување"
                  slotProps={{
                    textField: {
                      required: false,
                    },
                  }}
                  {...register("current_company_start_date")}
                  defaultValue={dayjs(initialValues.current_company_start_date) || null}
                  value={currentCompanyDate}
                  onChange={(date) => setValue("current_company_start_date", dayjs(date))}
                  disableFuture
                />
              </Grid>
            </Grid>
          )}

          <WorkExperienceForm />
          <PortfoliosBackground>
            <PageContainer paddingOverride="5px 17px 15px 12px">
              <Typography variant="h5" color="secondary" alignSelf="flex-start">
                Портфолио
              </Typography>
              <PortfolioForm />
            </PageContainer>
          </PortfoliosBackground>
          <Typography variant="h5" color="secondary">
            Социјални мрежи
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Facebook"
                {...register("facebook")}
                fullWidth
                placeholder="https://www.facebook.com/..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Facebook />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="LinkedIn"
                {...register("linkedin")}
                fullWidth
                placeholder="https://www.linkedin.com/..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedIn />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Twitter"
                {...register("twitter")}
                fullWidth
                placeholder="https://www.twitter.com/..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Twitter />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="GitHub"
                {...register("github")}
                fullWidth
                placeholder="https://www.github.com/..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GitHub />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Card>
              <Typography
                variant="h4"
                sx={{
                  padding: 3,
                  fontWeight: 700,
                }}
              >
                Промо период! Регистрирајте се сега и добијте бесплатно користење{" "}
                <span style={{ color: "red" }}> 1 месец.</span>
              </Typography>
            </Card>
          </Box>
          <PricingMain />
          <Button
            type="submit"
            variant="contained"
            disabled={createPost.isLoading}
            fullWidth
            color="primary"
          >
            {isEditing ? "Измени" : "Креирај"}
          </Button>
        </Form>
      </FormProvider>
    </PageContainer>
  );
};

export default CreatePostForm;
