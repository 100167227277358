import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

const mock = [
  {
    title: "1 месец",
    subtitle: "",
    price: { originalPrice: "", discountedPrice: "99 ден." },
    features: ["1 оглас", "1 категорија"],
    isHighlighted: false,
  },
  {
    title: "3 месеци",
    subtitle: "",
    price: { originalPrice: "300", discountedPrice: "249 ден." },
    features: ["3 огласи", "3 категории"],
    isHighlighted: true,
  },
  {
    title: "6 месеци",
    subtitle: "",
    price: { originalPrice: "600", discountedPrice: "499 ден." },
    features: ["Неограничени огласи", "Неограничени категории"],
    isHighlighted: false,
  },
];

const PricingMain = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [pricingOption, setPricingOption] = useState("discountedPrice");
  const [selectedCard, setSelectedCard] = useState<number | null>(null); // State to track selected card

  const handleClick = (event: React.MouseEvent, newPricingOption: any) => {
    setPricingOption(newPricingOption);
  };

  const handleSelect = (index: number) => {
    setSelectedCard(index);
  };

  const renderToggler = () => (
    <Box display={"flex"} justifyContent={"center"} marginBottom={4}>
      <ToggleButtonGroup value={pricingOption} exclusive onChange={handleClick}>
        <ToggleButton
          value="discountedPrice"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "discountedPrice"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: pricingOption === "discountedPrice" ? "common.white" : "text.primary",
            }}
          >
            discountedPrice
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="originalPrice"
          size={isMd ? "large" : "small"}
          sx={{
            backgroundColor:
              pricingOption === "originalPrice"
                ? `${theme.palette.primary.light} !important`
                : "transparent",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: pricingOption !== "discountedPrice" ? "common.white" : "text.primary",
            }}
          >
            originalPrice
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          marginTop: -13,
          paddingTop: 13,
        }}
      ></Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} md={4} key={i}>
              <Box
                component={Card}
                height={1}
                display={"flex"}
                flexDirection={"column"}
                variant={"outlined"}
                sx={{
                  background: selectedCard === i ? `red` : 'none',
                }}
              >
                <CardContent
                  sx={{
                    padding: 4,
                  }}
                >
                  <Box marginBottom={2}>
                    <Typography variant={"h4"} gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography color={"text.secondary"}>{item.subtitle}</Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"baseline"} marginBottom={2}>
                    <Typography
                      variant={"h3"}
                      sx={{
                        textDecoration: "line-through",
                      }}
                    >
                      {item.price.originalPrice}
                    </Typography>
                    <Typography variant={"h3"} fontWeight={700}>
                      {pricingOption === "discountedPrice"
                        ? item.price.discountedPrice
                        : item.price.originalPrice}
                    </Typography>
                  </Box>
                </CardContent>
                <Box flexGrow={1} />
                <CardActions sx={{ justifyContent: "center", padding: 2 }}>
                  <Button fullWidth
                    size={"large"}
                    variant={"outlined"}
                    onClick={() => handleSelect(i)} // Set selected card on click
                    //set button background to red
                    sx={{
                      background: selectedCard === i ? `${theme.palette.primary.main}` : 'none',
                      color: selectedCard === i ? `white` : 'none',
                      //hover color black
                      '&:hover': {
                        color: 'white',
                        background:`${theme.palette.primary.main}`

                      }
                    }}
                  >
                    Избери
                  </Button>
                </CardActions>
                <Typography
                  sx={{
                    padding: 3,
                    fontWeight: 700,
                  }}
                >
                  Промо период! Регистрирајте се сега и добијте бесплатно користење{" "}
                  <span style={{ color: "red" }}> 1 месец.</span>
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default PricingMain;
